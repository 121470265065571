











import Vue from 'vue';
import Component from 'vue-class-component';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { DeviceSessionConfig } from '@/models/data/models';

@Component({
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      DeviceSessionConfig,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeviceSessionConfigForm extends Vue {
  beforeSaveHook(session: DeviceSessionConfig) {
    if (this.$props.id === '0') {
      session.application = this.$store.getters['global/application'].id;
    }
  }
}
